window.addEventListener('DOMContentLoaded', () => {
  const accordion = document.querySelectorAll('.accordion');

  accordion.forEach((accordion) => {
    accordion.addEventListener('click', (e) => {
      const activePanel = e.target.closest('.accordion__content');
      if (!activePanel) return;
      toggleAccordion(activePanel);
    });

    function toggleAccordion(panelToActivate) {
      const activeButton = panelToActivate.querySelector('button');
      const activePanel = panelToActivate.querySelector('.accordion__text');
      const activePanelIsOpened = activeButton.getAttribute('aria-expanded');

      if (activePanelIsOpened === 'true') {
        panelToActivate
          .querySelector('button')
          .setAttribute('aria-expanded', false);

        panelToActivate
          .querySelector('.accordion__text')
          .setAttribute('aria-hidden', true);
      } else {
        // Close all other panels
        const panels = Array.from(
          document.querySelectorAll('.accordion__content')
        );
        panels.forEach((panel) => {
          if (panel !== panelToActivate) {
            panel.querySelector('button').setAttribute('aria-expanded', false);

            panel
              .querySelector('.accordion__text')
              .setAttribute('aria-hidden', true);
          }
        });

        panelToActivate
          .querySelector('button')
          .setAttribute('aria-expanded', true);

        panelToActivate
          .querySelector('.accordion__text')
          .setAttribute('aria-hidden', false);
      }
    }
  });
});
